import React, { useEffect, useState } from "react"
import "./Exam.less"
import { Button, Checkbox, Col, Row } from "antd"
import { FieldTimeOutlined } from "@ant-design/icons"
import { formatDuration, uiHandleError } from "../utils"
import { useTranslation } from "react-i18next"
import _ from "lodash"

const Exam = ({ data, onSubmit }) => {
  const { t } = useTranslation()
  const [questionIndex, setQuestionIndex] = useState(0)
  const [countDown, setCountDown] = useState(data.duration)
  const [questionCount, setQuestionCount] = useState(0)
  const [answers, setAnswers] = useState([])
  const [intervalId, setIntervalId] = useState(null)
  const [currentQuiz, setCurrentQuiz] = useState(null)

  const onPageClose = evt => {
    evt.preventDefault()
    evt.returnValue = ""
    return t("warning:leaveExamMessage")
  }

  useEffect(() => {
    setCountDown(data.duration)
    setQuestionCount(data.quizzes.length)
    const _intervalId = setInterval(() => {
      setCountDown(prev => prev - 1000)
    }, 1000)
    setIntervalId(_intervalId)

    window.addEventListener("beforeunload", onPageClose)

    return () => {
      clearInterval(_intervalId)
      window.removeEventListener("beforeunload", onPageClose)
    }
  }, [])

  useEffect(() => {
    let quiz = data.quizzes[questionIndex]

    quiz.answers = _.shuffle(quiz.answers)
    setCurrentQuiz(quiz)
  }, [questionIndex])

  useEffect(() => {
    if (countDown <= 0) {
      clearInterval(intervalId)
      onSubmit(answers)
      uiHandleError({
        error: new Error(t("error:examTimeout")),
      })
    }
  }, [countDown, intervalId])

  return (
    <div className="exam-wrapper">
      <div className="exam-header">
        <Row>
          <Col xs={24} sm={24} md={6}>
            <h1 className="question-label">
              {t("label:question")} {questionIndex + 1} / {questionCount}
            </h1>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <h1 className="exam-title">{data.title}</h1>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <h1 className="countdown-label">
              <FieldTimeOutlined /> {formatDuration(countDown / 1000)}{" "}
              {t("label:remains")}
            </h1>
          </Col>
        </Row>
      </div>
      <Row align="center">
        <Col xs={24} sm={24} md={20} lg={14}>
          {currentQuiz && (
            <>
              <h2>{t("label:question")}:</h2>
              <hr />
              <p className="question-text">{currentQuiz.question}</p>
              <h2>{t("label:chooseRightAnswer")}:</h2>
              <hr />
              <ul className="answer-wrapper">
                {currentQuiz.answers.map(answer => (
                  <li key={answer.id}>
                    <Checkbox
                      checked={answers.includes(answer.id)}
                      onChange={evt => {
                        let _answers = [...answers]
                        if (evt.target.checked) {
                          _answers.push(answer.id)
                        } else {
                          _answers.splice(_answers.indexOf(answer.id), 1)
                        }
                        setAnswers(_answers)
                      }}
                    >
                      {answer.content}
                    </Checkbox>
                  </li>
                ))}
              </ul>
              <br />
              <div className="action-buttons-wrapper">
                {questionIndex === questionCount - 1 ? (
                  <Button
                    className="yellowsp"
                    onClick={() => {
                      onSubmit(answers)
                    }}
                  >
                    {t("button:submitExam")}
                  </Button>
                ) : (
                  <Button
                    className="yellowsp"
                    onClick={() => {
                      setQuestionIndex(questionIndex + 1)
                    }}
                  >
                    {t("button:nextQuestion")}
                  </Button>
                )}
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default Exam
