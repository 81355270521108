import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import QueryString from "query-string"
import useCertification from "../../hooks/useCertification"
import PageRoot from "../../components/PageRoot"
import { Skeleton } from "antd"
import ErrorScreen from "../../components/ErrorScreen"
import Exam from "../../components/Exam"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import { devLog } from "../../utils"
import ExamResult from "../../components/ExamResult"

const StartExamPage = ({ location }) => {
  const { t } = useTranslation()
  const { startExam, verifyExam } = useCertification()

  const params = QueryString.parse(location.search)
  const { id } = params
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [examData, setExamData] = useState(null)
  const [examResult, setExamResult] = useState(null)

  const loadExam = async () => {
    setLoading(true)
    try {
      const data = await startExam(id)
      devLog({ examData: data })
      setExamData(data)
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    loadExam()
  }, [id])

  const handleExamSubmit = async data => {
    setLoading(true)
    try {
      const quizIds = examData.quizzes.map(item => item.id)

      const result = await verifyExam({
        quizIds,
        certificationId: id,
        examId: examData.id,
        answers: data,
      })

      devLog({
        quizIds,
        certificationId: id,
        examId: examData.id,
        answers: data,
      })

      setExamResult({ ...result, exam: examData, certificationId: id })
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }

  const content = useMemo(() => {
    if (loading) {
      return (
        <ContainerWithPadding size="small">
          <Skeleton active round />
        </ContainerWithPadding>
      )
    }

    if (error) {
      return (
        <ContainerWithPadding size="small">
          <ErrorScreen error={error} learnMoreUrl={`/go?id=${id}`} />
        </ContainerWithPadding>
      )
    }

    if (examResult) {
      return <ExamResult data={examResult} />
    }

    return (
      <ContainerWithPadding size="large">
        <Exam data={examData} onSubmit={handleExamSubmit} />
      </ContainerWithPadding>
    )
  }, [error, examData, loading, examResult])

  return <PageRoot title={t("label:startExam")}>{content}</PageRoot>
}

export default StartExamPage
